registrationPageCtrl.$inject = ['$state', '$uibModalInstance'];

export default function registrationPageCtrl($state, $uibModalInstance) {
    const vm = this;
    vm.goToSuccessPage = function() {
        return $state.go('content', { code: 'registercomplete' });
    };
    vm.close = function() {
        $uibModalInstance.dismiss();
        $state.go('login');
    };
}
