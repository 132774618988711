TaxInfoService.$inject = ['$http', '$q', 'SignOnTasks'];

export default function TaxInfoService($http, $q, SignOnTasks) {
    return {
        getTaxInfo,
        sendTaxInfo,
        validateSinOrSsn,
        BusinessTaxTypes_Dropdown
    };

    async function BusinessTaxTypes_Dropdown() {
        const res = await $http.get(`/api/business-tax-types-dropdown`);
        return res.data;

    }

    async function getTaxInfo() {
        const res = await $http.get(`/api/tax-info`);
        return res.data;
    }

    async function sendTaxInfo({
        firstName, lastName, companyName, sinNumber, address1, address2,
        city, province, postalCode, countryID, phone, email, businessTaxTypeID, isElectronic, sinSetupID
    }) {
        const res = await $http.post('/api/tax-info', {
            firstName,
            lastName,
            companyName,
            sinNumber,
            address1,
            address2,
            city,
            province,
            postalCode,
            countryID,
            phone,
            email,
            businessTaxTypeID,
            isElectronic,
            sinSetupID
        });
        await $q.all([
            SignOnTasks.removeTask({ type: 'TaxInfo' }),
            SignOnTasks.removeTask({ type: 'ConfirmTaxInfo' })
        ]);
        return res.data;
    }

    /**
     * @param SINTypeID {int} - 2 = Canada SIN, 3 = USA SSN, 4 = Business Tax ID
     * @param string {String} - SIN,  SSN, or Business Tax ID
     * @returns {boolean} - true if SIN / SSN / Business Tax ID is valid
     */
    function validateSinOrSsn(SINTypeID, string) {
        if (typeof string !== 'string') {
            return false;
        }

        // replace extra characters
        string = string.replace(/-|\s/g, '');
        if (string.length !== 9) {
            return false;
        }

        const number = Number(string);
        if (isNaN(number)) {
            return false;
        }

        switch (SINTypeID) {
            case 2:
                return validateSin(string);
            case 3:
                return validateSsn(string);
            case 4:
                return validateBusinessTaxID(string);
            }
    }

    /**
     * https://en.wikipedia.org/wiki/Luhn_algorithm
     * @param number {int}
     * @returns {boolean}
     */
    function validateSin(number) {
        const digits = splitDigits(number);

        const doubleEveryOther = digits.map(function(digit, i) {
            if (i % 2 === 0) {
                return digit;
            }
            return digit * 2;
        });

        const sumEveryOtherProduct = doubleEveryOther.map(function(num, i) {
            if (i % 2 === 0) {
                return num;
            }
            if (num > 9) {
                const productDigits = splitDigits(num);
                return productDigits[0] + productDigits[1];
            } else {
                return num;
            }
        });

        const sumAllDigits = sumEveryOtherProduct.reduce((digit, sum) => sum + digit, 0);

        return sumAllDigits % 10 === 0;
    }

    function splitDigits(number) {
        return number.toString().split('').map(Number);
    }

    /**
     * @param number {int}
     * @returns {boolean}
     */
    function validateSsn(number) {
        const numberString = number.toString();

        const areaNumber = Number(numberString.substring(0, 3));
        const groupCode = Number(numberString.substring(3, 5));
        const serialNumber = Number(numberString.substring(5));

        return !(areaNumber === 666 || areaNumber > 899 || areaNumber < 1 || groupCode < 1 || serialNumber < 1);
    }
    /**
     * @param number {int}
     * @returns {boolean}
     */
    function validateBusinessTaxID(number) {
        const numberString = number.toString();
        return (numberString.length === 9);
    }

    
}
