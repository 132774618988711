import _ from 'lodash';

taxInfoFormCtrl.$inject = ['$translate', '$exceptionHandler', 'GeoService', 'TaxInfoService', 'Session'];

export default function taxInfoFormCtrl($translate, $exceptionHandler, GeoService, TaxInfoService, Session) {
    this.$onInit = () => {
        const vm = this;
        vm.company = Session.getCompany();
        vm.user = Session.getUser();

        // in case a user ever gets to the tax-info page and their company isn't set up for sinCollection
        if (!vm.company.sinInfoCollection) {
            return;
        }
        vm.model = {};
        vm.sinSetupID = vm.user.sinSetupID;

        const userSinSetup = vm.company.sinInfoCollection.find((sinSetup) => sinSetup.sinSetupID === vm.user.sinSetupID);
        const sinTypeID = userSinSetup ? userSinSetup.sinTypeID : null;

        vm.description = userSinSetup && !vm.editing ? userSinSetup.optionalDescription : userSinSetup.verifyDescription;

        let sinTypeInfo;

        if (sinTypeID === 2) {
            sinTypeInfo = {
                countryName: 'Canada',
                countryID: 276,
                countryCode: 'ca',
                sinName: 'app_TAX_INFO_LABEL_SIN_NAME_CANADA', // Social Insurance Number
                postalCodeName: 'app_TAX_INFO_LABEL_POSTAL_CODE', // Postal Code
                provinceType: 'app_TAX_INFO_LABEL_PROVINCE', // Province
                taxfieldSpliceLocation: 2
            };
        } else if (sinTypeID === 3) {
            sinTypeInfo = {
                countryName: 'United States',
                countryID: 463,
                countryCode: 'us',
                sinName: 'app_TAX_INFO_LABEL_SIN_NAME_USA', // Social Security Number
                postalCodeName: 'app_TAX_INFO_LABEL_ZIP_CODE', // Zip Code
                provinceType: 'app_TAX_INFO_LABEL_STATE', // State
                taxfieldSpliceLocation: 2
            };
        } else if (sinTypeID === 4) {
            sinTypeInfo = {
                countryName: 'United States',
                countryID: 463,
                countryCode: 'us',
                sinName: 'app_TAX_INFO_LABEL_BUSINESS_TAX_NUMBER', // Business Tax Number
                postalCodeName: 'app_TAX_INFO_LABEL_ZIP_CODE', // Zip Code
                provinceType: 'app_TAX_INFO_LABEL_STATE', // State
                taxfieldSpliceLocation: 4
            };
        }
        vm.submitButtonOptions = _.merge({
            settings: {
                initial: {
                    translateKey: 'app_TAX_INFO_SUBMIT_BUTTON' // Submit your tax information
                },
                success: {
                    noReset: true,
                    isDisabled: true
                }
            }
        }, vm.submitButtonOptions || {});

        vm.fields = [{
            key: 'firstName',
            type: 'customInput',
            templateOptions: {
                type: 'text',
                labelClass: 'col-sm-4',
                controlClass: 'col-sm-8 fs-hide-without-consent',
                labelTranslateKey: 'app_TAX_INFO_LABEL_FIRST_NAME', // Legal First Name
                required: true
            }
        }, {
            key: 'lastName',
            type: 'customInput',
            templateOptions: {
                type: 'text',
                labelClass: 'col-sm-4',
                controlClass: 'col-sm-8 fs-hide-without-consent',
                labelTranslateKey: 'app_TAX_INFO_LABEL_LAST_NAME', // Legal Last Name
                required: true
            }
        },{
            key: 'address1',
            type: 'customInput',
            templateOptions: {
                type: 'text',
                labelClass: 'col-sm-4',
                controlClass: 'col-sm-8 fs-hide-without-consent',
                labelTranslateKey: 'app_TAX_INFO_LABEL_ADDRESS_1', // Address 1
                required: true
            }
        }, {
            key: 'address2',
            type: 'customInput',
            templateOptions: {
                type: 'text',
                labelClass: 'col-sm-4',
                controlClass: 'col-sm-8 fs-hide-without-consent',
                labelTranslateKey: 'app_TAX_INFO_LABEL_ADDRESS_2', // Address 2
            }
        }, {
            key: 'city',
            type: 'customInput',
            templateOptions: {
                type: 'text',
                labelClass: 'col-sm-4',
                controlClass: 'col-sm-8 fs-hide-without-consent',
                labelTranslateKey: 'app_TAX_INFO_LABEL_CITY', // City
                required: true
            }
        }, {
            key: 'province',
            type: 'customSelect',
            templateOptions: {
                labelTranslateKey: sinTypeInfo.provinceType,
                labelClass: 'col-sm-4',
                controlClass: 'col-sm-8',
                required: true,
                valueProp: 'code'
            },
            controller: ['$scope', function($scope) {
                GeoService.getProvinceList(sinTypeInfo.countryCode)
                    .then(function(provinceList) {
                        $scope.to.options = provinceList;
                    })
                    .catch(function(err) {
                        // TODO: consider doing this automatically for all dynamic customSelect options
                        $exceptionHandler(err);
                    });
            }]
        }, {
            key: 'postalCode',
            type: 'customInput',
            templateOptions: {
                type: 'text',
                labelClass: 'col-sm-4',
                controlClass: 'col-sm-8 fs-hide-without-consent',
                labelTranslateKey: sinTypeInfo.postalCodeName,
                required: true
            }
        }, {
            key: 'country',
            type: 'customInput',
            defaultValue: sinTypeInfo.countryName,
            templateOptions: {
                type: 'text',
                labelClass: 'col-sm-4',
                controlClass: 'col-sm-8',
                labelTranslateKey: 'app_TAX_INFO_LABEL_COUNTRY', // Country
                required: true,
                disabled: true
            }
        }, {
            key: 'phone',
            type: 'customInput',
            templateOptions: {
                type: 'text',
                labelClass: 'col-sm-4',
                controlClass: 'col-sm-8 fs-hide-without-consent',
                labelTranslateKey: 'app_TAX_INFO_LABEL_PHONE_NUMBER', // Phone
            }
        }, {
            key: 'email',
            type: 'customInput',
            templateOptions: {
                type: 'text',
                labelClass: 'col-sm-4',
                controlClass: 'col-sm-8 fs-hide-without-consent',
                labelTranslateKey: 'app_TAX_INFO_LABEL_EMAIL' // Email
            }
        }, {
            noFormControl: true,
            template: `<div class="tax-info-form-border"></div>`
        }, {
            key: 'isElectronic',
            type: 'customRadioInput',
            defaultValue: true,
            templateOptions: {
                options: [{
                    translateKey: 'app_TAX_INFO_MAIL_TYPE_ELECTRONIC', // The secure way, I will download from this site when notified'
                    value: true
                }, {
                    translateKey: 'app_TAX_INFO_MAIL_TYPE_SNAIL_MAIL', // I'm old school: Send it by snail mail
                    value: false
                }],
                labelTranslateKey: 'app_TAX_INFO_MAIL_TYPE_HEADER' // How would you like to receive this information at tax time?
            }
        }, {
            key: 'confirmInformation',
            type: 'customCheckbox',
            templateOptions: {
                labelTranslateKey: 'app_TAX_INFO_CONFIRM_INFO', // I confirm that information provided is correct.
                required: true,
            },
            validation: {
                messages: {
                    required: () => $translate.instant('app_TAX_INFO_CONFIRM_INFO_ERROR_MESSAGE')
                }
            }
        }];  

        if (sinTypeID === 4) {        
            
            vm.fields.splice(2, 0, {
                key: 'companyName',
                type: 'customInput',
                templateOptions: {
                    type: 'text',
                    labelClass: 'col-sm-4',
                    controlClass: 'col-sm-8 fs-hide-without-consent',
                    labelTranslateKey: 'app_TAX_INFO_LABEL_BUSINESS_NAME', // Legal Business Name
                    required: true
                }
            });

            vm.fields.splice(3, 0, {
                key: 'businessTaxTypeID',
                type: 'customSelect',
                templateOptions: {
                    labelTranslateKey:  'app_TAX_INFO_BUSINESS_REGISTRATION_TYPE', // Business Registration Type
                    labelClass: 'col-sm-4',
                    controlClass: 'col-sm-8',
                    required: true,
                    valueProp: 'code'
                },
                controller: ['$scope', function($scope) {
                    TaxInfoService.BusinessTaxTypes_Dropdown()
                        .then(function(businessTaxTypes) {
                            $scope.to.options = businessTaxTypes;
                        })
                        .catch(function(err) {
                            // TODO: consider doing this automatically for all dynamic customSelect options
                            $exceptionHandler(err);
                        });
                }]

            });
        }

        if (!vm.editing) {
            vm.fields.splice(sinTypeInfo.taxfieldSpliceLocation, 0, {
                key: 'sinNumber',
                type: 'customInput',
                templateOptions: {
                    type: 'text',
                    labelClass: 'col-sm-4',
                    controlClass: 'col-sm-8 fs-hide',
                    labelTranslateKey: sinTypeInfo.sinName,
                    required: true
                },
                validators: {
                    existingSIN: {
                        expression: function(viewValue, modelValue) {
                            const sinNumber = modelValue || viewValue;
                            if (!sinNumber) {
                                return false;
                            }
                            return TaxInfoService.validateSinOrSsn(sinTypeID, sinNumber);
                        },
                        message: function() {
                            if (sinTypeID === 4){
                                return $translate.instant('app_TAX_INFO_VALIDATOR_VALID_BUSINESS_TAX_NUMBER'); // Must enter a valid Business Tax Number
                            }else{
                                return $translate.instant('app_TAX_INFO_VALIDATOR_VALID_SIN'); // Must enter a valid SIN/SSN
                            }
                        }
                    }
                }
            });
        } else {
            TaxInfoService.getTaxInfo()
                .then(function(taxInfo) {
                    Object.assign(vm.model, {
                        firstName: taxInfo.firstName,
                        lastName: taxInfo.lastName,
                        companyName: taxInfo.companyName,
                        address1: taxInfo.address1,
                        address2: taxInfo.address2,
                        city: taxInfo.city,
                        province: taxInfo.provinceCode,
                        postalCode: taxInfo.postalCode,
                        phone: taxInfo.phone,
                        email: taxInfo.email,
                        businessTaxTypeID: taxInfo.businessTaxTypeID,
                        isElectronic: Boolean(taxInfo.isElectronicDelivery)
                    });
                });
        }

        vm.onSubmit = function onSubmit() {
            const companyName =  vm.model.companyName || null;
            const businessTaxTypeID =  vm.model.businessTaxTypeID || null;

            return TaxInfoService.sendTaxInfo({
                firstName: vm.model.firstName,
                lastName: vm.model.lastName,
                companyName: companyName,
                sinNumber: vm.model.sinNumber,
                address1: vm.model.address1,
                address2: vm.model.address2,
                city: vm.model.city,
                province: vm.model.province,
                postalCode: vm.model.postalCode,
                countryID: sinTypeInfo.countryID,
                phone: vm.model.phone,
                email: vm.model.email,
                businessTaxTypeID: businessTaxTypeID,
                isElectronic: vm.model.isElectronic,
                sinSetupID: vm.sinSetupID
            });
        };
    };
}
