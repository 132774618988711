import registrationModalTpl from './registrationModal.tpl.html';

RegistrationModalService.$inject = ['$uibModal', '$state'];

export default function RegistrationModalService($uibModal, $state) {
    return {
        openRegistrationModal
    };

    function openRegistrationModal() {
        $state.go('registration');      
    }
}
