import _ from 'lodash';

promoHeaderCtrl.$inject = ['Session', '$translate', 'PromoService', 'PromoTypes', 'PromoRibbon', 'PromoStatuses'];

export default function promoHeaderCtrl(Session, $translate, PromoService, PromoTypes, PromoRibbon, PromoStatuses) {
    this.$onInit = () => {
        const vm = this;

        vm.user = Session.getUser();
        vm.isAward = vm.promo.promoTypeID === PromoTypes.AWARD;
        vm.promoCardImage = PromoService.getPromoImageUrl(vm.promo);
        vm.promoGroups = Session.getCompany().incentiveGroups || [];
        const promoGroupID = (vm.isAward)? vm.promo.awardGroupID : vm.promo.incentiveGroupID;
        if (vm.promoGroups.length) {
            const groupIndex = vm.promoGroups.findIndex(group => group.id === promoGroupID);
            if (vm.promoGroups[groupIndex]) {
                vm.promoGroupName = vm.promoGroups[groupIndex].name;
            }
        }
        PromoRibbon.getRibbon(vm.promo).then(ribbons => {
            vm.ribbons = ribbons;
        }).catch(err => {
            console.warn(err);
        });

        vm.crumbTrailEditName = _.truncate(vm.promo.name);

        // ------------------------------
        // Permissions and promo statuses
        // ------------------------------
        vm.showActionHeader = vm.user.hasPermission('ManagePromos') || vm.user.hasPermission('IncentiveReports');
        vm.statusInfo = {};
        vm.showResultsOptions = ![PromoStatuses.DELETED, PromoStatuses.DRAFT].includes(vm.promo.promoStatusID)
                                && !vm.promo.isTemplate;

        vm.showGameOption = vm.showResultsOptions && vm.user.hasPermission('IsGameAdmin') && vm.promo.gameID;

        // Defined the statusInfo to show the right status on promo.
        if (vm.promo.isTemplate) {
            vm.statusInfo = {
                text: 'Template',
                cssClass: 'template',
                iconClass: '',
            };
        } else {
            switch (vm.promo.promoStatusID) {
                case PromoStatuses.DRAFT:
                    vm.statusInfo = {
                        text: $translate.instant('promo_STATUS_DRAFT'),
                        cssClass: 'draft',
                        iconClass: '',
                    };
                    break;
                case PromoStatuses.PENDING:
                    vm.statusInfo = {
                        text: $translate.instant('promo_STATUS_PENDING'),
                        cssClass: 'pending',
                        iconClass: 'fa fa-clock-o',
                    };
                    break;
                case PromoStatuses.ACTIVE:
                    vm.statusInfo = {
                        text: $translate.instant('promo_STATUS_ACTIVE'),
                        cssClass: 'active',
                        iconClass: 'fa fa-check-circle',
                    };
                    break;
                case PromoStatuses.COMPLETE:
                    vm.statusInfo = {
                        text: $translate.instant('promo_STATUS_COMPLETE'),
                        cssClass: 'complete',
                        iconClass: '',
                    };
                    break;
                case PromoStatuses.PAUSED:
                    vm.statusInfo = {
                        text: $translate.instant('promo_STATUS_PAUSED'),
                        cssClass: 'paused',
                        iconClass: 'fa fa-pause-circle',
                    };
                    break;
                case PromoStatuses.ARCHIVED:
                    vm.statusInfo = {
                        text: $translate.instant('promo_STATUS_ARCHIVED'),
                        cssClass: 'archived',
                        iconClass: '',
                    };
                    break;
                case PromoStatuses.DELETED:
                    vm.statusInfo = {
                        text: $translate.instant('promo_STATUS_DELETED'),
                        cssClass: 'deleted',
                        iconClass: 'fa fa-ban',
                    };
                    break;
            }
        }

        // Show breadcrumb on 'edit' or 'results' screens
        // Depends on the promoType we need to load the iframe on click of breadcrumb
        const allCrumbTrailLinks = {
            allAwards: {
                show: (function showAwardLink() {
                    return vm.user.hasPermission('ManagePromos')
                        && vm.promo.promoTypeID === PromoTypes.AWARD;
                }()),
                uiSref: `legacyFramePage({ urlPath:'Report_Promos.asp?PromoTypeID=${vm.promo.promoTypeID}' })`,
                uiSrefOpts: '{reload: false}',
                cssClass: 'all-award-link',
                text: $translate.instant('promo_CRUMBTRAIL_ALL_AWARDS'),
            },
            allGames: {
                show: (function showAllGamesLink() {
                    return vm.user.hasPermission('ManagePromos')
                        && [PromoTypes.GAME_WITH_QUESTION, PromoTypes.GAME_WITHOUT_QUESTION,
                            PromoTypes.SURVEY_GAME].includes(vm.promo.promoTypeID);
                }()),
                uiSref: `legacyFramePage({ urlPath:'Report_Promos.asp?PromoType=games' })`,
                cssClass: 'all-game-link',
                text: $translate.instant('promo_CRUMBTRAIL_ALL_GAMES'),
            },
            gameWithQuestion: {
                show: (function showLearningGamesLink() {
                    return vm.user.hasPermission('ManagePromos')
                        && vm.promo.promoTypeID === PromoTypes.GAME_WITH_QUESTION;
                }()),
                uiSref: `legacyFramePage({ urlPath:'Report_Promos.asp?PromoTypeID=${vm.promo.promoTypeID}' })`,
                uiSrefOpts: '{reload: false}',
                cssClass: 'learning-game-link',
                text: $translate.instant('promo_CRUMBTRAIL_LEARNING_GAMES'),
            },
            gameWithoutQuestion: {
                show: (function showFunGamesLink() {
                    return vm.user.hasPermission('ManagePromos')
                        && vm.promo.promoTypeID === PromoTypes.GAME_WITHOUT_QUESTION;
                }()),
                uiSref: `legacyFramePage({ urlPath:'Report_Promos.asp?PromoTypeID=${vm.promo.promoTypeID}' })`,
                uiSrefOpts: '{reload: false}',
                cssClass: 'fun-game-link',
                text: $translate.instant('promo_CRUMBTRAIL_GAMES_WITHOUT_QUESTIONS'),
            },
            allTrainings: {
                show: (function showTrainingLink() {
                    return vm.user.hasPermission('ManagePromos')
                        && vm.promo.promoTypeID === PromoTypes.TRAINING;
                }()),
                uiSref: `legacyFramePage({ urlPath:'Report_Promos.asp?PromoTypeID=${vm.promo.promoTypeID}' })`,
                uiSrefOpts: '{reload: false}',
                cssClass: 'all-training-link',
                text: $translate.instant('promo_CRUMBTRAIL_ALL_TRAINING'),
            },
            allIncentives: {
                show: (function showAllIncentiveLink() {
                    return vm.user.hasPermission('ManagePromos')
                        && [PromoTypes.CLAIM_BASED, PromoTypes.DATA_BASED,
                            PromoTypes.BULK_UPLOAD, PromoTypes.MARKETING].includes(vm.promo.promoTypeID);
                }()),
                uiSref: `legacyFramePage({ urlPath:'Report_Promos.asp?PromoType=incentives' })`,
                cssClass: 'all-incentive-link',
                text: $translate.instant('promo_CRUMBTRAIL_ALL_INCENTIVES'),
            },
            claimBased: {
                show: (function showClaimBasedLink() {
                    return vm.user.hasPermission('ManagePromos')
                        && vm.promo.promoTypeID === PromoTypes.CLAIM_BASED;
                }()),
                uiSref: `legacyFramePage({ urlPath:'Report_Promos.asp?PromoTypeID=${vm.promo.promoTypeID}' })`,
                uiSrefOpts: '{reload: false}',
                cssClass: 'claim-based-link',
                text: $translate.instant('promo_CRUMBTRAIL_CLAIM_BASED'),
            },
            dataBased: {
                show: (function showDataBasedLink() {
                    return vm.user.hasPermission('ManagePromos')
                        && vm.promo.promoTypeID === PromoTypes.DATA_BASED;
                }()),
                uiSref: `legacyFramePage({ urlPath:'Report_Promos.asp?PromoTypeID=${vm.promo.promoTypeID}' })`,
                uiSrefOpts: '{reload: false}',
                cssClass: 'data-based-link',
                text: $translate.instant('promo_CRUMBTRAIL_DATA_BASED'),
            },
            bulkUploads: {
                show: (function showBulkUploadLink() {
                    return vm.user.hasPermission('ManagePromos')
                        && vm.promo.promoTypeID === PromoTypes.BULK_UPLOAD;
                }()),
                uiSref: `legacyFramePage({ urlPath:'Report_Promos.asp?PromoTypeID=${vm.promo.promoTypeID}' })`,
                uiSrefOpts: '{reload: false}',
                cssClass: 'bulk-upload-link',
                text: $translate.instant('promo_CRUMBTRAIL_BULK_UPLOADS'),
            },
            marketingIncentives: {
                show: (function showMarketingLink() {
                    return vm.user.hasPermission('ManagePromos')
                        && vm.promo.promoTypeID === PromoTypes.MARKETING;
                }()),
                uiSref: `legacyFramePage({ urlPath:'Report_Promos.asp?PromoTypeID=${vm.promo.promoTypeID}' })`,
                uiSrefOpts: '{reload: false}',
                cssClass: 'marketing-link',
                text: $translate.instant('promo_CRUMBTRAIL_MARKETING_INCENTIVES'),
            },
            allSurveys: {
                show: (function showSurveyLink() {
                    return vm.user.hasPermission('ManagePromos')
                        && vm.promo.promoTypeID === PromoTypes.SURVEY;
                }()),
                uiSref: `legacyFramePage({ urlPath:'Report_Promos.asp?PromoTypeID=${vm.promo.promoTypeID}' })`,
                uiSrefOpts: '{reload: false}',
                cssClass: 'all-survey-link',
                text: $translate.instant('promo_CRUMBTRAIL_ALL_SURVEYS'),
            },
            surveyGames: {
                show: (function showSurveyGamesLink() {
                    return vm.user.hasPermission('ManagePromos')
                        && vm.promo.promoTypeID === PromoTypes.SURVEY_GAME;
                }()),
                uiSref: `legacyFramePage({ urlPath:'Report_Promos.asp?PromoTypeID=${vm.promo.promoTypeID}' })`,
                uiSrefOpts: '{reload: false}',
                cssClass: 'survey-game-link',
                text: $translate.instant('promo_CRUMBTRAIL_SURVEY_GAMES'),
            },
        };

        // Showing only required links by permissions and statuses
        vm.crumbTrailLinks = _(allCrumbTrailLinks).pickBy(link => link.show).values().value();
    };
}
