import _ from 'lodash';

Session.$inject = ['$rootScope', '$localStorage', '$sessionStorage', '$window', 'Company', 'Contact', '$translate', '$http'];

export default function Session($rootScope, $localStorage, $sessionStorage, $window, Company, Contact, $translate, $http) {

    if (!$localStorage.localStorageNoResetProperties) {
        $localStorage.localStorageNoResetProperties = ['localStorageNoResetProperties', 'sessionStorageNoResetProperties'];
    }
    if (!$localStorage.sessionStorageNoResetProperties) {
        $localStorage.sessionStorageNoResetProperties = [];
    }

    const Events = {
        SESSION_DESTROYED: 'SESSION_DESTROYED'
    };

    return {
        Events,
        getUser,
        getCompany,
        getSpectator,
        setUser,
        setCompany,
        setSpectator,
        destroy,
        resetStorage,
        destroySessions,
        preventLocalStorageReset,
        preventSessionStorageReset,
        refreshPointBalance
    };

    function getUser() {
        return $rootScope.user;
    }

    function getCompany() {
        return $rootScope.company;
    }

    function getSpectator() {
        return $rootScope.spectator;
    }

    /**
     * @param {Object} company - Company data and variables rendered into index.ejs by the server.
     *                          publicCompanySession when logged out, authCompanySession when logged in
     */
    function setCompany(company) {
        return $rootScope.company = $window.rnSession.company = new Company(company);
    }

    /**
     * @param {Object} user - User data from the API response after successfully logging in,
     *                      or rendered into index.ejs by the server.
     *                      - can also be used to override user session
     */
    function setUser(user) {
        const company = getCompany();
        return $rootScope.user = $window.rnSession.user = new Contact(company, user);
    }

    /**
     * @param {Object} spectator
     */
    function setSpectator(spectator) {
        const company = getCompany();
        return $rootScope.spectator = $window.rnSession.spectator = new Contact(company, spectator);
    }

    /**
     * TODO: a better name than "destroy", it's actually more like a "reset", some data is kept and some data is wiped
     * Currently ran on logout.
     */
    function destroy() {
        resetStorage();
        destroySessions();
    }

    /**
     * TODO: move to a separate dedicated browser storage service/factory
     */
    function resetStorage() {
        const localStorageDefaults = {};
        $localStorage.localStorageNoResetProperties.forEach(prop => localStorageDefaults[prop] = $localStorage[prop]);
        $localStorage.$reset(localStorageDefaults);

        const sessionStorageDefaults = {};
        $localStorage.sessionStorageNoResetProperties.forEach(prop => sessionStorageDefaults[prop] = $sessionStorage[prop]);
        $sessionStorage.$reset(sessionStorageDefaults);
    }

    function destroySessions() {
        destroyUser();
        destroyCompany();
        destroySpectator();
        $rootScope.$broadcast(Events.SESSION_DESTROYED);
    }

    /**
     * Allows other services to prevent certain local storage variables from being reset on logout
     * @param {String} properties
     */
    function preventLocalStorageReset(...properties) {
        $localStorage.localStorageNoResetProperties = _.uniq($localStorage.localStorageNoResetProperties.concat(properties));
    }

    /**
     * Allows other services to prevent certain session storage variables from being reset on logout
     * @param {String} properties
     */
    function preventSessionStorageReset(...properties) {
        $localStorage.sessionStorageNoResetProperties = _.uniq($localStorage.sessionStorageNoResetProperties.concat(properties));
    }

    function destroyUser() {
        delete $rootScope.user;
        delete $window.rnSession.user;
    }

    function destroySpectator() {
        delete $rootScope.spectator;
        delete $window.rnSession.spectator;
    }

    function destroyCompany() {
        // Keep everything but navBarLinks and permissions
        Object.keys($rootScope.company.permissionGroups).forEach(code => {
            const permissionGroup = $rootScope.company.permissionGroups[code];
            delete permissionGroup.navBarLinks;
            delete permissionGroup.permissions;
        });
    }

    /**
     * Update the user's points
     * @param {number} points - The new points value
     */
    async function refreshPointBalance(points, animate = false) {
        const user = await getUser();
        // Ignore the refresh if the user is not logged in
        if (!user) {
            return;
        } else if ([null, undefined].includes(points)) { // No points defined so fetch from the server
            try {
                const response = await $http.get('/api/refreshPointBalance');
                if (response.data && response.data.points !== undefined) {
                    points = response.data.points;
                } else {
                    return;
                }
            } catch (error) {
                return;
            }
        }
        if (points !== user.points) {
            if (animate) {
                const element = document.querySelector('.navbar-points'); // Adjust selector as needed
                if (element) {
                    element.classList.add('point-animation');
                    if (points > user.points) {
                        element.classList.add('point-animation-plus');
                    } else if (points < user.points) {
                        element.classList.add('point-animation-minus');
                    }
                }
                // Listen for the animationend event
                element.addEventListener('animationend', function handleAnimationEnd() {
                    $rootScope.user.points = points;
                    $window.rnSession.user.points = points;
                    // Remove the classes
                    element.classList.remove('point-animation'); 
                    element.classList.remove('point-animation-plus');
                    element.classList.remove('point-animation-minus');
                    // Clean up the event listener
                    element.removeEventListener('animationend', handleAnimationEnd); 
                });
            } 
            $rootScope.user.points = points;
            $window.rnSession.user.points = points;
        }
    }
}
