import _ from 'lodash';
import bowser from 'bowser';

registrationCtrl.$inject = ['$scope','$translate', 'RegistrationService', 'SuggestService', '$q', 'Session', 'AuthStatus', 'SuggestsTypes', '$timeout','$location'];

export default function registrationCtrl($scope, $translate, RegistrationService, SuggestService, $q, Session, AuthStatus, SuggestsTypes, $timeout, $location) {
    this.$onInit = () => {
        // TODO Future Development, each company (or permission role or signUpType) will have a register setup. If there are multiple setups, they are determined by a querystring.
        // Register setup will include fields to display (codeName & type), along with IsMandatory, and optional RegEx.
        // Certain codeNames or types will automatically make call ajax calls to validate in line.  ex: Uniqueness, return limiters.
        // Manager -> determined dynamically by Register Setup (no Option will be needed)
        // Entity -> determined dynamically by Register Setup, but Option "Allow Entities" must be enabled
        // CreateEntity -> Option "Allow Entities" must be enabled
        // Note: currently we use option=SelfRegisterEntities must be enabled, but this could be replaced with Register Setup

        const vm = this;
        // Passed from component bindings:
        // vm.onSuccess

        _.defaults(vm, {
            onSuccess: _.noop
        });

        vm.model = {};

        vm.submitButtonOptions = {
            settings: {
                initial: {
                    translateKey: 'registration_SUBMIT_BUTTON_ENROLL_NEW' // Enroll Now
                }
            }
        };

        vm.usernameIsUnique = true;
        vm.userIdIsUnique = true;
        vm.companyUserIdIsUnique = true;

        vm.company = Session.getCompany();
        vm.userCanRegisterCompany = vm.company.hasOption('SelfRegisterEntities');
        vm.isMultiLanguage = vm.company.hasOption('MultiLanguage');
        vm.showCompanyRegisterFields = false;

        vm.showCompanyFormlyField = vm.company.hasOption('AllowEntities');
        vm.showManagerFormlyField = vm.company.hasOption('SelfRegistrationSelectsManager');

        const languageOptions = vm.company.languageOptions;
        const defaultLanguage = vm.company.defaultLanguageCode.toUpperCase();
        const defaultLanguageSelectOption = languageOptions[defaultLanguage];
        const defaultLanguageID = defaultLanguageSelectOption.ID;
        const disableAutoFillForChrome = (elementPath) => {
            if (bowser.chrome) {
                $timeout(() => {
                    $(elementPath).attr('autocomplete', 'chrome-off');
                }, 800);
            }
        };


        const isParameterCheckSumValid = isChecksumValid($location);
        // simple defaults
        let defaultFirstName;
        let defaultLastName;
        let defaultEmail;
        let defaultPhone;
        let defaultStartedOn;
        let defaultBirthday;
        let defaultUsername;
        let defaultUserID;

        let defaultLimiters = new Array(11).fill(null);

        if (isParameterCheckSumValid) {
            // Get query parameters
            var params = $location.search();        

            // simple defaults
            defaultFirstName = params[`firstName`];
            defaultLastName = params[`lastName`];
            defaultEmail = params[`email`];
            defaultPhone = params[`phone`];
            defaultStartedOn = params[`startedOn`];
            defaultBirthday = params[`birthday`];
            defaultUsername = params[`username`];
            defaultUserID = params[`userID`];

            
            // limiters - require api call for confirmation
            for (let i = 1; i <= 10; i++) {
                const value = params[`limiter${i}`];
                if (value !== null) {
                    defaultLimiters[i] = value;
                }
            }

            // other:  company, manager
        }


        disableAutoFillForChrome('.company-field input');
        disableAutoFillForChrome('.manager-field input');

        const languageNames = Object.keys(languageOptions).map((code) => ({
            name: code,
            value: languageOptions[code]
        }));

        if (vm.permissionGroupOptions) {
            vm.permissionGroupOptions = vm.permissionGroupOptions.map((option) => ({
                name: option.name,
                value: option.id
            }));
        }

        let defaultPermissionGroupOptions;
        // If only one option is available then auto select it as default
        if (vm.permissionGroupOptions && vm.permissionGroupOptions.length === 1) {
            defaultPermissionGroupOptions = vm.permissionGroupOptions[0].value;
        }

        const publicLimiters = (vm.company.limiters || []).filter(limiter => limiter.isDisplayPublic && limiter.isEntityLimiter===0);
        const publicEntityLimiters = (vm.company.limiters || []).filter(limiter => limiter.isDisplayPublic && limiter.isEntityLimiter);

        vm.fieldsBeforeLimiters = [
            {
                key: 'firstName',
                type: 'customInput',
                templateOptions: {
                    type: 'text',
                    placeholderTranslateKey: 'registration_FIRST_NAME_PLACEHOLDER',
                    controlClass: 'col-sm-4 fs-hide-without-consent first-name-field',
                    required: true
                },
                defaultValue: defaultFirstName
            },
            {
                key: 'middleName',
                type: 'customInput',
                templateOptions: {
                    type: 'text',
                    placeholderTranslateKey: 'registration_MIDDLE_NAME_PLACEHOLDER',
                    controlClass: 'col-sm-4 fs-hide-without-consent middle-name-field'
                }
            },
            {
                key: 'lastName',
                type: 'customInput',
                templateOptions: {
                    type: 'text',
                    placeholderTranslateKey: 'registration_LAST_NAME_PLACEHOLDER',
                    controlClass: 'col-sm-4 fs-hide-without-consent last-name-field',
                    required: true
                },
                defaultValue: defaultLastName
            },
            {
                key: 'username',
                type: 'customInput',
                modelOptions: {
                    debounce: {
                        default: 1000,
                        blur: 0
                    },
                    updateOn: 'default blur'
                },
                defaultValue: defaultUsername,
                templateOptions: {
                    type: 'text',
                    placeholderTranslateKey: 'registration_USERNAME_PLACEHOLDER',
                    controlClass: 'username-field',
                    required: true,
                    onKeypress: function($viewValue, $modelValue, scope) {
                        if (!vm.usernameIsUnique) {
                            vm.usernameIsUnique = true;
                            vm.rnFormCtrl.checkFormValidity();
                        }
                    },
                },
                validators: {
                    unique: {
                        expression: function(viewValue, modelValue) {
                            return !!vm.usernameIsUnique;
                        },
                        message: function () {
                            return $translate.instant('registration_VALIDATE_UNIQUE_USERNAME'); //  This username has already been used
                        }
                    },
                },
            },
            {
                key: 'userID',
                type: 'customInput',
                modelOptions: {
                    debounce: {
                        default: 1000,
                        blur: 0
                    },
                    updateOn: 'default blur'
                },
                defaultValue: defaultUserID,
                templateOptions: {
                    type: 'text',
                    placeholderTranslateKey: 'registration_USERID_PLACEHOLDER',
                    controlClass: 'user-id-field',
                    onKeypress: function($viewValue, $modelValue, scope) {
                        if (!vm.userIdIsUnique) {
                            vm.userIdIsUnique = true;
                            vm.rnFormCtrl.checkFormValidity();
                        }
                    },
                },
                validators: {
                    unique: {
                        expression: function(viewValue, modelValue) {
                            return !!vm.userIdIsUnique;
                        },
                        message: function () {
                            return $translate.instant('registration_VALIDATE_UNIQUE_USER_ID'); //  This username has already been used
                        }
                    },
                },
            },
            {
                key: 'email',
                type: 'customInput',
                templateOptions: {
                    type: 'email',
                    placeholderTranslateKey: 'registration_EMAIL_PLACEHOLDER',
                    controlClass: 'fs-hide-without-consent email-field',
                    required: true
                },
                defaultValue: defaultEmail
            },
            {
                key: 'phone',
                type: 'customInput',
                templateOptions: {
                    type: 'text',
                    controlClass: 'fs-hide-without-consent phone-field',
                    placeholderTranslateKey: 'registration_PHONE_PLACEHOLDER',
                },
                defaultValue: defaultPhone
            },
            {
                key: 'language',
                type: 'customSelect',
                defaultValue: defaultLanguageSelectOption,
                templateOptions: {
                    options: languageNames,
                    controlClass: 'language-field'
                },
                hideExpression: function() {
                    return !vm.isMultiLanguage;
                }
            }
        ];

        if (publicLimiters.length > 0) {
            vm.limiterFields = publicLimiters.map(function (limiter) {
                disableAutoFillForChrome(`.limiter-${limiter.num}-field input`);
                return {
                    key: 'limiter' + limiter.num,
                    type: 'customSuggest',
                    data: {
                        limiterOrder: limiter.num,
                    },
                    templateOptions: {
                        type: SuggestsTypes.LIMITERS_PRE_LOGIN,
                        placeholder: limiter.name,
                        controlClass: 'limiter-' + limiter.num + '-field',
                        autocomplete: false,
                    },
                    validators: {
                        validDropdown: {
                            expression: function(viewValue, modelValue) {

                                if (!isParameterCheckSumValid || isParameterCheckSumValid && !defaultLimiters[limiter.num]) {
                                    // no default values, so no validation needed
                                    return true
                                }
                                
                                return defaultLimiters[limiter.num] !== modelValue;
                            },
                            message: function() {
                                return $translate.instant('registration_INVALID_DEFAULT_LIMITER'); // Invalid choice, please pick a field from the dropdown
                            }
                        }
                    }
                };
            });
        }


        if (publicEntityLimiters.length > 0) {
            vm.limiterEntityFields = publicEntityLimiters.map(function (limiter) {
                disableAutoFillForChrome(`.limiter-${limiter.num}-field input`);
                return {
                    key: 'limiter' + limiter.num,
                    type: 'customSuggest',
                    data: {
                        limiterOrder: limiter.num,
                    },
                    templateOptions: {
                        type: SuggestsTypes.LIMITERS_PRE_LOGIN,
                        placeholder: limiter.name,
                        controlClass: 'limiter-' + limiter.num + '-field',
                        autocomplete: false,
                    },
                    hideExpression: function() {
                        return !vm.showCompanyRegisterFields;
                    }
                };
            });
        }

        vm.fieldsAfterLimiters = [
            {
                key: 'birthday',
                type: 'customCalendar',
                templateOptions: {
                    placeholderTranslateKey: 'registration_BIRTHDAY_PLACEHOLDER',
                    format: 'MMMM dd',
                    uibDatepickerOptions: {
                        maxMode: 'month',
                        formatDayTitle: 'MMMM',
                        formatMonthTitle: 'MMMM'
                    },
                    controlClass: 'fs-hide-without-consent birthday-field'
                },
                defaultValue: defaultBirthday

            },
            {
                key: 'startDay',
                type: 'customCalendar',
                templateOptions: {
                    placeholderTranslateKey: 'registration_START_DATE_PLACEHOLDER',
                    format: 'MMMM dd, yyyy',
                    controlClass: 'fs-hide-without-consent start-day-field'
                },
                defaultValue: defaultStartedOn
            },
            {
                key: 'company',
                type: 'customSuggest',
                templateOptions: {
                    type: SuggestsTypes.COMPANY,
                    placeholderTranslateKey: 'registration_COMPANY_PLACEHOLDER',
                    controlClass: 'company-field',
                    autocomplete: false,
                },
                hideExpression: function() {
                    return vm.showCompanyRegisterFields || !vm.showCompanyFormlyField;
                }
            },
            {
                key: 'manager',
                type: 'customSuggest',
                templateOptions: {
                    type: SuggestsTypes.USER_POTENTIAL_MANAGER,
                    placeholderTranslateKey: 'registration_MANAGER_PLACEHOLDER',
                    controlClass: 'manager-field',
                    autocomplete: false,
                },
                expressionProperties: {
                    'templateOptions.disabled' : function(viewValue, modelValue, scope) {
                        if (scope.model.company) {
                            return !scope.model.company.ID;
                        } else {
                            return true;
                        }
                    },
                    'data.entityID': function(viewValue, modelValue, scope) {
                        if (scope.model.company && scope.model.company.ID) {
                            return scope.model.company.ID;
                        }
                    }
                },
                hideExpression: function() {
                    return vm.showCompanyRegisterFields || !vm.showManagerFormlyField;
                }
            },
            {
                template:
                    `<div class="company-field-area" ng-if="vm.userCanRegisterCompany">
                        <span class="register-company-text" translate="registration_NO_COMPANY_MESSAGE_A">
                            If your company doesn't show up in the list above
                        </span>, <a ng-click="vm.toggleRegisterFields()" class="open-company-info" translate="registration_NO_COMPANY_MESSAGE_B">register it now.</a>
                    </div>`,
                controller: ['$scope', function ($scope) {
                    $scope.vm = vm;
                }],
                hideExpression: function() {
                    return vm.showCompanyRegisterFields;
                }
            },
            {
                template:
                    `<div class="company-info-container">
                        <div class="company-info-header">
                            <span class="company-info-header-text" translate="registration_COMPANY_INFORMATION_HEADER">
                                Your Company's Information
                            </span>
                            <div class="close-company-info" ng-click="vm.toggleRegisterFields()" translate="registration_DONT_REGISTER_COMPANY">
                                I don't want to register a company
                            </div>
                        </div>
                    </div>`,
                controller: ['$scope', function ($scope) {
                    $scope.vm = vm;
                }],
                hideExpression: function() {
                    return !vm.showCompanyRegisterFields;
                }
            },
            {
                key: 'companyName',
                type: 'customInput',
                templateOptions: {
                    type: 'text',
                    placeholderTranslateKey: 'registration_COMPANY_NAME_PLACEHOLDER',
                    controlClass: 'company-name-field',
                    required: true
                },
                hideExpression: function() {
                    return !vm.showCompanyRegisterFields;
                }
            },
            {
                key: 'companyUserID',
                type: 'customInput',
                modelOptions: {
                    debounce: {
                        default: 1000,
                        blur: 0
                    },
                    updateOn: 'default blur'
                },
                templateOptions: {
                    type: 'text',
                    placeholderTranslateKey: 'registration_COMPANYID_PLACEHOLDER',
                    controlClass: 'company-user-id-field',
                    onKeypress: function($viewValue, $modelValue, scope) {
                        if (!vm.companyUserIdIsUnique) {
                            vm.companyUserIdIsUnique = true;
                            vm.rnFormCtrl.checkFormValidity();
                        }
                    },
                },
                hideExpression: function() {
                    return !vm.showCompanyRegisterFields;
                },
                validators: {
                    unique: {
                        expression: function(viewValue, modelValue) {
                            return !!vm.companyUserIdIsUnique;
                        },
                        message: function () {
                            return $translate.instant('registration_VALIDATE_COMPANY_USER_ID'); // This has already been submitted
                        }
                    },
                },
            },
            {
                key: 'companyRole',
                type: 'customSelect',
                defaultValue: defaultPermissionGroupOptions,
                templateOptions: {
                    options: vm.permissionGroupOptions,
                    controlClass: 'company-role-field',
                    valueProp: ''
                },
                hideExpression: function() {
                    return !vm.showCompanyRegisterFields;
                }
            }
        ];

        vm.captchaFields = !AuthStatus.isAuthenticated() && vm.company.hasOption('CaptchaPreLogin') ? [{
            key: 'captchaToken',
            type: 'customCaptcha'
        }] : [];


        if (vm.limiterEntityFields) {
            vm.fieldsAfterLimiters = vm.fieldsAfterLimiters.concat(vm.limiterEntityFields);
        }

        if (vm.limiterFields) {
            vm.fields = vm.fieldsBeforeLimiters.concat(vm.limiterFields, vm.fieldsAfterLimiters, vm.captchaFields);
        } else {
            vm.fields = vm.fieldsBeforeLimiters.concat(vm.fieldsAfterLimiters, vm.captchaFields);
        }


        // limiter defaults
        if (isParameterCheckSumValid) {
            for (let LimiterOrder = 1; LimiterOrder <= 10; LimiterOrder++) {
                if (typeof defaultLimiters[LimiterOrder] !== 'undefined') {
                    const LimiterDefaultValue = defaultLimiters[LimiterOrder] ;

                    // make api call to confirm the default limiter value
                    SuggestService.getLimitersPreLogin({limiterOrder: LimiterOrder, searchText:LimiterDefaultValue}).then(suggestResults => {

                        // if suggestResults has a phrase that matches LimiterDefaultValue, then ID is the correct value
                        const MatchingLimiterEntry = suggestResults.find(item => item.phrase === LimiterDefaultValue);
                        const LimiterEntryID = MatchingLimiterEntry ? MatchingLimiterEntry.ID : 0;
                        
                        if (LimiterEntryID > 0){
                            // if valid, update the limiter suggests with the correct value
                            vm.model["limiter" + LimiterOrder] = {phrase: LimiterDefaultValue};

                            // add class to indicate limiter default is valid, so we can hide it for some clients
                            const limiterField = document.querySelector(`.limiter-${LimiterOrder}-field`);
                            if (limiterField) {
                                limiterField.classList.add('registration-limiter-default-valid');
                            }

                        } else if (LimiterEntryID === -1) {
                            // if not valid, highlight and show warning
                            vm.model["limiter" + LimiterOrder] = LimiterDefaultValue;
                            vm.rnFormCtrl.checkFormValidity();
                        }
                    });
                }
            }            
        }

        vm.toggleRegisterFields = function () {
            vm.showCompanyRegisterFields = !vm.showCompanyRegisterFields;
            // TODO: why do we need to manually run the hideExpressions? might be a simpler solution available
            vm.fields
                .filter(field => field.hideExpression)
                .forEach(field => field.hide = field.hideExpression());
        };

        vm.onSubmit = async function onSubmit() {
            const fieldsToSubmit = {
                firstName: vm.model.firstName,
                middleName: vm.model.middleName,
                lastName: vm.model.lastName,
                username: vm.model.username,
                userID: vm.model.userID,
                email: vm.model.email,
                phone: vm.model.phone,
                birthday: vm.model.birthday,
                startDay: vm.model.startDay,
                company: vm.model.company ? vm.model.company.ID : undefined,
                companyName: vm.model.companyName,
                companyUserID: vm.model.companyUserID,
                companyRole: vm.model.companyRole,
                language: vm.model.language ? vm.model.language.ID : defaultLanguageID,
                limiter1: vm.model.limiter1 ? vm.model.limiter1.phrase : undefined,
                limiter2: vm.model.limiter2 ? vm.model.limiter2.phrase : undefined,
                limiter3: vm.model.limiter3 ? vm.model.limiter3.phrase : undefined,
                limiter4: vm.model.limiter4 ? vm.model.limiter4.phrase : undefined,
                limiter5: vm.model.limiter5 ? vm.model.limiter5.phrase : undefined,
                limiter6: vm.model.limiter6 ? vm.model.limiter6.phrase : undefined,
                limiter7: vm.model.limiter7 ? vm.model.limiter7.phrase : undefined,
                limiter8: vm.model.limiter8 ? vm.model.limiter8.phrase : undefined,
                limiter9: vm.model.limiter9 ? vm.model.limiter9.phrase : undefined,
                limiter10: vm.model.limiter10 ? vm.model.limiter10.phrase : undefined,
                managerContactID: vm.model.manager ? vm.model.manager.ID : undefined,
                captchaToken: vm.model.captchaToken
            };

            if (vm.showCompanyRegisterFields) {
                Object.assign(fieldsToSubmit, {
                    company: undefined,
                });
            } else {
                // Not registering a new company
                Object.assign(fieldsToSubmit, {
                    companyName: undefined,
                    companyUserID: undefined,
                    companyRole: undefined,
                });
            }

            try {
                try {
                    await RegistrationService.checkUsernameIsUnique(fieldsToSubmit.username);
                } catch (err) {
                    vm.usernameIsUnique = false;
                    throw err;
                }
                if(fieldsToSubmit.userID) {
                    try {
                        await RegistrationService.checkUserIDIsUnique(fieldsToSubmit.userID);
                    } catch (err) {
                        vm.userIdIsUnique = false;
                        throw err;
                    }
                }
                if (vm.company.hasOption('SelfRegisterEntities')) {
                    try {
                        await RegistrationService.checkUserIDIsUnique(fieldsToSubmit.companyUserID);
                    } catch (err) {
                        vm.companyUserIdIsUnique = false;
                        throw err;
                    }
                }
                await RegistrationService.sendRegistration(fieldsToSubmit);
                vm.onSuccess();
            } catch(err) {
                vm.rnFormCtrl.checkFormValidity();
                throw err.data;
            }
        };
    };

    function isChecksumValid($location) {
        // Get the full query string object
        var queryParams = $location.search();
   
        // Extract the provided checkSum
        var providedChecksum = queryParams.checkSum;
        if (!providedChecksum) {
            return false; // No checkSum provided
        }

        // Create a copy of the query parameters without the checkSum
        var filteredParams = angular.copy(queryParams);
        delete filteredParams.checkSum;
    
        // Convert the remaining query parameters back into a query string
        var queryString = Object.keys(filteredParams)
            .sort() // Sort keys to maintain consistency
            .map(key => key + "=" + filteredParams[key])
            .join("&");
    
        // Generate checkSum and compare
        var computedChecksum = simpleChecksum(queryString);
        //console.log("Checksum: " + computedChecksum); // helpful for debugging

        return computedChecksum == providedChecksum;
    }

    function simpleChecksum (data) {
        let checksum = 0;

        // Iterate through each character in the data
        for (let i = 0; i < data.length; i++) {
            // Add the ASCII value of 
            //  the character to the checksum
            checksum += data.charCodeAt(i);
        }
    
        // Ensure the checksum is within 
        //the range of 0-255 by using modulo
        return checksum % 256;
    }
}
