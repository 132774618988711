import _ from 'lodash';
import $ from 'jquery';
import bowser from 'bowser';

export const screens = {
    CREDENTIALS: 'credentials',
    FORGOT_PASSWORD: 'forgot-password',
    SSO: 'sso',
    PRIVATE_SSO: 'private-sso',
};

export const displayTypes = {
    SSO_FAILURE: 'sso-failure',
}

loginPanelCtrl.$inject = [
    '$scope', '$state', '$transitions', '$window', '$location', 'Session', 'LangUtils', 'AuthService', 'AuthStatus',
    'AuthStateService', 'AUTH_EVENTS', 'SignOnTasks', 'RegistrationModalService', 'SimpleModal', 'StateUtils',
    'SsoService', 'SsoTypes', '$cookies', '$timeout', '$http',
];

export default function loginPanelCtrl(
    $scope, $state, $transitions, $window, $location, Session, LangUtils, AuthService, AuthStatus,
    AuthStateService, AUTH_EVENTS, SignOnTasks, RegistrationModalService, SimpleModal, StateUtils,
    SsoService, SsoTypes, $cookies, $timeout, $http,
) {
    const vm = this;
    vm.company = Session.getCompany();
    vm.alerts = []; // For error messages (Ex. invalid password)
    vm.userCanRegister = vm.company.hasOption('SelfRegister');
    vm.openRegistration = function() {
        $state.go('registration');
    }

    vm.languageOptions = Object.keys(vm.company.languageOptions || {}).map(code => vm.company.languageOptions[code]);
    vm.isChangeLanguageAvailable = vm.company.hasOption('MultiLanguage');
    vm.currentLanguage = LangUtils.getLang().toUpperCase();
    vm.changeLanguage = LangUtils.changeLanguage;
    vm.screens = screens;
    vm.SsoTypes = SsoTypes;
    const ssoEndpoints = vm.company.ssoEndpoints.map(ssoEndpoint => ({
        ...ssoEndpoint,
        endpoint: ssoEndpoint.ssoTypeID === SsoTypes.DIRECT_SIGN_IN
            ? $state.href('login', { screen: screens.CREDENTIALS }) : ssoEndpoint.endpoint,
    }));
    vm.publicSsoEndpoints = ssoEndpoints.filter(ssoEndpoint => ssoEndpoint.isPublic);
    vm.privateSsoEndpoints = ssoEndpoints.filter(ssoEndpoint => !ssoEndpoint.isPublic);
    
    vm.activeScreen = determineInitialScreen();
    vm.cookiesEnabled = !!$cookies.get('XSRF-TOKEN');

    vm.isInvalidBrowser = false;
    vm.isSunsetBrowser = false;

    if (!_.isEmpty(vm.company.loginPageLinks)) {
        // Assume all loginPageLinks are custom content pages.
        vm.loginPageLinks = vm.company.loginPageLinks.map(link => ({
            ...link,
            url: `/content/${link.code}`,
        }));
    }

    vm.model = {};

    vm.fields = [{
        key: 'username',
        type: 'customInput',
        templateOptions: {
            type: 'text',
            labelTranslateKey: 'app_LOGIN_PANEL_USERNAME_LABEL', // Username
            placeholderTranslateKey: 'app_LOGIN_PANEL_USERNAME_PLACEHOLDER', // Username
            controlClass: 'col-sm-12',
            labelSrOnly: true,
            required: true,
            autocomplete: false,
            disabled: !vm.cookiesEnabled,
        },
    }, {
        key: 'password',
        type: 'customInput',
        templateOptions: {
            type: 'password',
            labelTranslateKey: 'app_LOGIN_PANEL_PASSWORD_LABEL', // Password
            placeholderTranslateKey: 'app_LOGIN_PANEL_PASSWORD_PLACEHOLDER', // Password
            controlClass: 'col-sm-12',
            labelSrOnly: true,
            required: true,
            autocomplete: false,
            minLength: 5,
            disabled: !vm.cookiesEnabled,
        },
    }];

    if (!AuthStatus.isAuthenticated() && vm.company.hasOption('CaptchaPreLogin')) {
        vm.fields.push({
            key: 'captchaToken',
            type: 'customCaptcha',
            templateOptions: {
                controlClass: 'col-sm-12',
            },
        });
    }

    vm.submitButtonOptions = {
        settings: {
            initial: {
                translateKey: 'app_LOGIN_PANEL_SIGN_IN_BUTTON', // Sign in
            },
            send: {
                translateKey: 'app_LOGIN_PANEL_SIGN_IN_BUTTON', // Sign in
            },
        },
    };

    $scope.$on(AUTH_EVENTS.LOGOUT_SUCCESS, function() {
        vm.showLoginPanel = true;
        let signOutUrl;
        if (vm.company.environmentInstance && ['live', 'uat', 'dr'].includes(vm.company.environmentInstance.toLowerCase())) {
            signOutUrl = `${vm.company.subDomain}-admin.iziigame.com`;
        } else {
            signOutUrl = `${vm.company.subDomain}-admin.vibe.smg`;
        }
        $http.get(`https://${signOutUrl}/api/auth/sign-out`);
    });

    // TODO: find a way so that this check isn't needed here and only in the transition
    // right now the transition doesn't run when loading the site for the first time
    vm.showLoginPanel = $state.current.name === 'login'
        || ($state.current.data.isPublicPage && !AuthStatus.isAuthenticated());
    $transitions.onStart({}, function(transition) {
        const toState = transition.to();
        // Determine vm.showLoginPanel here instead of on LOGIN_SUCCESS because the user might remain on the login page
        // after LOGIN_SUCCESS (if they have required sign on tasks for example).
        vm.showLoginPanel = toState.name === 'login' || (toState.data.isPublicPage && !AuthStatus.isAuthenticated());
    });

    $($window).on('hashchange', updateScreenOnHashChange);

    $scope.$on('$destroy', () => {
        $($window).off('hashchange', updateScreenOnHashChange);
    });

    vm.submit = () => $timeout( async () => {
        await AuthService.login(vm.model)
    });

    vm.onSubmitSuccess = function() {
        const tasksSeries = SignOnTasks.lockAppWithRequiredTasks();
        tasksSeries.once('complete', async () => {
            await AuthStateService.goToNextStateAfterLogin();
            vm.showLoginPanel = false;
            return SignOnTasks.openOptionalTasksAfterLogin();
        });
    };

    vm.onSubmitFail = function(err) {
        if (err.data && err.data.returnCode === 1017) {
            // User logging in doesn't have a sinSetupID and we can't gather their sin information.
            return SimpleModal.open({
                type: 'error',
                // 'Sign in error'
                title: 'app_LOGIN_ERROR_UNABLE_TO_COLLECT_SIN_INFO_MODAL_TITLE',
                // 'We were unable collect your SIN/SSN information at this time. Please contact an administrator.'
                body: 'app_LOGIN_ERROR_UNABLE_TO_COLLECT_SIN_INFO_MODAL_BODY',
            });
        }
        return alertError(err);
    };

    vm.ssoRedirect = (url) => {
        $window.location.href = url;
    };

    vm.changeScreen = (screen) => {
        if (screen === vm.activeScreen) {
            return;
        }
        vm.activeScreen = screen;
        StateUtils.updateUrlParams({ screen });
    };

    vm.closeAlert = function(index) {
        vm.alerts.splice(index, 1);
    };

    // Old browser check for unsupported/sunset versions
    if (bowser.msie) {
        if (parseInt(bowser.version) < 11) {
            vm.isInvalidBrowser = true;
        } else if (parseInt(bowser.version) === 11) {
            vm.isSunsetBrowser = true;
        }
    }

    if (vm.isInvalidBrowser || vm.isSunsetBrowser) {
        vm.stillUseThisBrowser = false;
        vm.useThisBrowser = () => {
            vm.stillUseThisBrowser = true;
        };
        vm.learnMoreAboutBrowser = () => {
            vm.stillUseThisBrowser = false;
        };
    }

    // TODO: duplicated in loginDialog.controller.js, figure out way to share code
    function alertError(err) {
        let message;
        if (err.data.returnCode) {
            if (err.data.returnCode === 1015) {
                message = {
                    translateKey: 'app_LOGIN_ERROR_MESSAGE_USER_DEACTIVATED',
                };
            } else if (err.data.returnCode === 1054) {
                message = {
                    translateKey: 'app_ACCOUNT_LOCKOUT',
                    translateKeyValue: {
                        lockoutMinutes: err.data.outputJSON.lockoutMinutes,
                    },
                    showIcon: true,
                    iconClass: 'fa fa-lock',
                };
            } else {
                message = {
                    text: err.data.phrase,
                };
            }
        } else {
            message = {
                translateKey: 'app_LOGIN_DEFAULT_ERROR_MESSAGE', // 'Sorry, an error occurred'
            };
        }

        // We want to hide the error ID when invalid SSO (1011) or invalid password (1012) or account lockout (1054) because they're common.
        const hideErrorID = [1011, 1012, 1054].includes(err.data.returnCode);
        vm.alerts.unshift({
            type: 'danger',
            errorID: !hideErrorID && err.data.errorID,
            ...message,
        });
        if (vm.alerts.length >= 3) {
            vm.alerts.pop();
        }
    }

    if ($state.params.displayType) {
        vm.displayType = $state.params.displayType;
        if (vm.displayType === vm.displayTypes.SSO_FAILURE) {
            const err = {
                data: {
                    returnCode: 1011,
                    phrase: 'Invalid SSO Login attempt. Try other login options or contact your administrator if having issues login.', // 'Invalid SSO'
                }
            };
            return alertError(err);
        }
    }

    function updateScreenOnHashChange() {
        // hashchange event listener is needed because ui-router doesn't reload this controller on querystring changes.
        // An SSO endpoint could be a link to our login credentials page.
        // i.e. href="#/login?screen=credentials" --> would not reload this controller.
        if ($state.current.name !== 'login') {
            return;
        }
        const urlParams = $location.search();
        const screen = urlParams.screen && findScreen(urlParams.screen);
        if (screen) {
            vm.changeScreen(screen);
        }
    }

    function determineInitialScreen() {
        if ($state.params.screen) {
            const screen = findScreen($state.params.screen);
            if (screen) {
                return screen;
            }
        }
        if (_.isEmpty(vm.publicSsoEndpoints) || (vm.publicSsoEndpoints.length === 1 && vm.publicSsoEndpoints[0].ssoTypeID === SsoTypes.DIRECT_SIGN_IN)) {
            return screens.CREDENTIALS;
        }
        const ssoEndpoint = SsoService.getSelectedEndpoint();
        return ssoEndpoint && ssoEndpoint.ssoTypeID === SsoTypes.DIRECT_SIGN_IN ? screens.CREDENTIALS : screens.SSO;
    }

    function findScreen(string) {
        return Object.values(screens).find(screen => screen.toLowerCase() === string.toLowerCase());
    }
}
