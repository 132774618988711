import registrationPageTpl from './registrationPage.tpl.html';
import loginTpl from './../authentication/login/login.tpl.html';

registrationConfig.$inject = ['$stateProvider'];

export default function registrationConfig($stateProvider) {
    $stateProvider.state('registration', {
        parent: 'nav',
        url: '/registration',
        views: {
            'main@': {
                templateUrl: loginTpl,
            }
        },        
        onEnter: ['$uibModal', function($uibModal) {
            var modalInstance = $uibModal.open({
                templateUrl: registrationPageTpl,
                controller: 'registrationPageCtrl as vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });

            modalInstance.result.catch(function() {
                modalInstance.close();
            });

            modalInstance.closed.then(function() {
                modalInstance.close();
            });
        }],
        data: {
            pageTitle: 'Registration',
            isPublicPage: true
        },
        resolve: {
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('registration');
            }]
        }
    });
}
