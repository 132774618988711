import $ from 'jquery';

appRun.$inject = [
    '$rootScope', '$transitions', '$window', '$location', '$timeout', '$uibModalStack', 'StateUtils', 'Session'
];

export default function appRun(
    $rootScope, $transitions, $window, $location, $timeout, $uibModalStack, StateUtils, Session
) {
    const companyOptions = Session.getCompany().options;
    const googleAnalytics = companyOptions.GoogleAnalytics;
    const upgradeInProgress = companyOptions.UpgradeInProgress;

    let initialStateChangeSuccess = false;
    let pointsChecked = false; // Ensure points are checked only once

    function checkAndUpdatePoints() {
        if (!pointsChecked) {
            pointsChecked = true;
            Session.refreshPointBalance();
        }
    }

    if (googleAnalytics) {
        $window.ga('create', googleAnalytics.value, 'auto');
    }

    if (upgradeInProgress && $window.location.search.includes('?bypassKey=')) {
        $window.location.replace($window.location.origin + '/' + $window.location.hash);
    }
    
    const cssVar = new $window.CSSGlobalVariables();
    const cssVarKeys = Object.keys(cssVar);
    const lottieIDVarKeys = cssVarKeys.filter(key => key.includes('lottie-id'));
    const lottieClassVarKeys = cssVarKeys.filter(key => key.includes('lottie-class'));

    $rootScope.$on('$viewContentLoading', function(){
        $timeout(() => {
            if (lottieIDVarKeys.length) {
                lottieIDVarKeys.forEach(key => {
                    const elementID = key.replace('--lottie-id-', '');
                    const element = $(`#${elementID}`);
                    if (element.length && !$('#dotlottie-player-' + elementID).length) {
                        const lottieElement = document.createElement('dotlottie-player');
                        lottieElement.setAttribute('src', cssVar[key]);
                        lottieElement.setAttribute('id', 'dotlottie-player-' + elementID);
                        lottieElement.setAttribute('autoplay', true);
                        if (cssVar[key].includes('loop=true')) {
                            lottieElement.setAttribute('loop', true);
                        }
                        element[0].append(lottieElement);
                    }
                });
            }
            if (lottieClassVarKeys.length) {
                lottieClassVarKeys.forEach(key => {
                    const elementClass = key.replace('--lottie-class-', '');
                    const element = $(`.${elementClass}`);
                    if (element.length && !$('#dotlottie-player-' + elementClass).length) {
                        const lottieElement = document.createElement('dotlottie-player');
                        lottieElement.setAttribute('src', cssVar[key]);
                        lottieElement.setAttribute('id', 'dotlottie-player-' + elementClass);
                        lottieElement.setAttribute('autoplay', true);
                        if (cssVar[key].includes('loop=true')) {
                            lottieElement.setAttribute('loop', true);
                        }
                        element[0].append(lottieElement);
                    }
                });
            }
        });
    });

    

    $transitions.onStart({}, function(transition) {
        if (!initialStateChangeSuccess) {
            initialStateChangeSuccess = true;
            checkAndUpdatePoints();
            $('#root').removeClass('loading');
            $('.loading-spinner-container').remove();
            return;
        }

        const fromState = transition.from();

        if (initialStateChangeSuccess && StateUtils.isStateViewable(fromState) && !$rootScope.isRedirectingAfterLogin) {
            // Closes all open modals
            // Wait until after first state change because error modals could be opened before the first state change completes
            $uibModalStack.dismissAll();
        }
    });

    $transitions.onSuccess({}, () => {
        if (googleAnalytics) {
            $window.ga('send', 'pageview', $location.path());
        }
        const modalBackdropsDivs = $('.modal-backdrop');
        if (!$('body').hasClass('modal-open') && modalBackdropsDivs) {
            $('.modal-backdrop').each((i) => modalBackdropsDivs[i].remove());
        }
    });
}
