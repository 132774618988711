SuggestService.$inject = ['$http', 'SuggestsTypes', 'Session'];

export default function SuggestService($http, SuggestsTypes, Session) {

    const company = Session.getCompany();

    /**
     * @param {Object} options
     * @param {string} options.url
     * @returns {Function}
     */
    const createSuggestHandler = ({ url }) => async ({ searchText, useSpectator, excludeMe } = {}) => {
        const res = await $http.get(url, {
            params: {
                searchText: searchText || '',
                useSpectator: useSpectator || false,
                excludeMe: excludeMe || false,
            }
        });
        return res.data;
    };

    const getAnyContacts = createSuggestHandler({ url: '/api/suggests/contacts/any' });
    const getAccessibleContacts = createSuggestHandler({ url: '/api/suggests/contacts/accessible' });
    const getCompanies = createSuggestHandler({ url: '/api/suggests/companies' });
    const getMyBudgets = createSuggestHandler({ url: '/api/suggests/budgets' });
    const getMyGroups = createSuggestHandler({ url: '/api/suggests/groups' });
    const getContent = createSuggestHandler({url: '/api/suggests/content'});

    async function getPotentialManagers({ options, searchText }) {
        let res;
        if (typeof options.entityID === 'undefined') {
            res = await $http.get(`/api/suggests/contacts/potential-managers/`, {
                params: {
                    searchText
                }
            });
        } else {
            res = await $http.get(`/api/suggests/contacts/potential-managers/${options.entityID}`, {
                params: {
                    searchText
                }
            });
        }
        return res.data;

    }

    async function getNotDownStream({ contactID, searchText }) {
        const res = await $http.get(`/api/suggests/contacts/not-down-stream/${contactID}`, {
            params: {
                searchText
            }
        });
        return res.data;
    }

    async function getLimitersPreLogin({ limiterOrder, searchText }) {
        const res = await $http.get(`/api/suggests/limiters/pre-login/${limiterOrder}`, {
            params: {
                searchText
            }
        });
        return res.data;
    }

    async function getDatafeedColumn({ searchText, options }) {
        const res = await $http.get(`/api/suggests/datafeed-column`, {
            params: {
                searchText,
                ...options
            }
        });
        return res.data;
    }


    const typesToFunctions = {
        [SuggestsTypes.ANYONE]: getAnyContacts,
        [SuggestsTypes.ANY_COLLEAGUE]: (params) => getAnyContacts({ ...params, excludeMe: true }),
        [SuggestsTypes.USER_ACCESSIBLE]: getAccessibleContacts,
        [SuggestsTypes.USER_ACCESSIBLE_EXCLUDING_ME]: (params) => getAccessibleContacts({ ...params, excludeMe: true }),
        [SuggestsTypes.NOT_DOWNSTREAM]: getNotDownStream,
        [SuggestsTypes.MY_BUDGETS]: getMyBudgets,
        [SuggestsTypes.MY_GROUPS]: getMyGroups,
        [SuggestsTypes.USER_POTENTIAL_MANAGER]: getPotentialManagers,
        [SuggestsTypes.LIMITERS_PRE_LOGIN]: getLimitersPreLogin,
        [SuggestsTypes.COMPANY]: getCompanies,
        [SuggestsTypes.NAV]: company.hasOption('IsGlobalRecognition') ? getAnyContacts : getAccessibleContacts,
        [SuggestsTypes.CONTENT]: getContent,
        [SuggestsTypes.DATAFEED_COLUMN]: getDatafeedColumn,
    };

    /**
     * @param {Object} obj
     * @param {string} obj.type - From SuggestTypes constants
     * @returns {function(searchText: string)}
     */
    function getFunctionByType({ type, ...params }) {
        const func = typesToFunctions[type];
        return (searchText, options) => {
            return func({searchText, options, ...params});
        }
    }

    return {
        getFunctionByType,
        getLimitersPreLogin
    };

}
