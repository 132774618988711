import $ from 'jquery';
import moment from 'moment';

cardsCtrl.$inject = [
    '$state', '$stateParams', '$timeout', '$filter', '$translate', 'Session', 'cardSetup',
    'ReloadableCardService', 'RewardService', 'GeoService'
];

export default function cardsCtrl(
    $state, $stateParams, $timeout, $filter, $translate, Session, cardSetup,
    ReloadableCardService, RewardService, GeoService
) {
    const vm = this;
    const user = Session.getUser();
    vm.cardSetup = cardSetup;
    vm.cardTitle = vm.cardSetup.isNewCard ? vm.cardSetup.productNameNew : vm.cardSetup.productNameReload;
    vm.cardSubtitle = vm.cardSetup.isNewCard ? $translate.instant('reloadableCard_SUBTITLE_TXT') : $translate.instant('reloadableCard_SUBTITLE_NEW_TXT');
    vm.cardPopupTitle = vm.cardSetup.isNewCard ? $translate.instant('reloadableCard_SUBTITLE_TXT') : $translate.instant('reloadableCard_SUBTITLE_RELOAD_TXT');
    vm.currencyFilteredPreviousAmount = $filter('currency')(vm.cardSetup.lastReloadedAmount, undefined, 0);
    vm.isMaxValueSelected = false;
    vm.redemptionAddress = {};
    vm.isAddressEmpty = isObjectEmpty(vm.redemptionAddress);
    vm.languageCode = user.languageCode;

    vm.cardSetup.addressValidationJSON.postalCode.regex = vm.cardSetup.addressValidationJSON.postalCode.regex.replace(/\\/g, '');

    fetchAddressSetupData();
    async function fetchAddressSetupData() {
        try {
            const countries = await GeoService.getCountryList();
            const allowedCountry = countries.filter(country => vm.cardSetup.addressValidationJSON.country.allowedCountries.includes(country.code));

            if (allowedCountry.length === 1) {
                vm.countryList = allowedCountry;
                vm.redemptionAddress.countryObj = allowedCountry[0];
                await vm.updateProvinces();
                vm.disableCountryDropdown = true;
            } else if (allowedCountry.length > 1) {
                vm.countryList =[{ code: '', name: vm.cardSetup.addressValidationJSON.country.placeholder[vm.languageCode] }];
                allowedCountry.forEach(country => {
                    vm.countryList.push(country);
                });
                vm.redemptionAddress.countryObj = { code: '', name: vm.cardSetup.addressValidationJSON.country.placeholder[vm.languageCode] };
                vm.disableProvinceDropdown = true;
                vm.disableCountryDropdown = false;
            }
        } catch (err) {
            console.error(err);
        }
    }

    vm.updateProvinces = async () => {
        vm.disableProvinceDropdown = false;
        const countryCode = vm.redemptionAddress.countryObj ? vm.redemptionAddress.countryObj.code : null;
        vm.provinceList = [{ code: '', name: vm.cardSetup.addressValidationJSON.province.placeholder[vm.languageCode] }];
        vm.redemptionAddress.provinceObj = {code: '', name: vm.cardSetup.addressValidationJSON.province.placeholder[vm.languageCode]};
        if (!countryCode) {
            // No country code selected yet. Do nothing.
            return;
        }
        const provinces = await GeoService.getProvinceList(countryCode);
        provinces.forEach(province => {
            vm.provinceList.push(province);
        });
        vm.redemptionAddress.provinceObj = vm.provinceList[0];
    };

    function isObjectEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    function validateAddress(address1, address2, province, country) {
        if (!country.code) {
            return { addressValidated: false, message: vm.cardSetup.addressValidationJSON.country.badSyntaxMessage[vm.languageCode] };
        } else if (!province.code) {
            return { addressValidated: false, message: vm.cardSetup.addressValidationJSON.province.badSyntaxMessage[vm.languageCode] };
        } else if (address1.length > vm.cardSetup.addressValidationJSON.address1.length) {
            return { addressValidated: false, message: vm.cardSetup.addressValidationJSON.address1.badSyntaxMessage[vm.languageCode] };
        }
        const poBoxRegEx = new RegExp(/^.*((po box)|(pobox)|(po-box)).*$/gi);
        const extendedAddress = `${address1} ${address2}`
        const isPOBoxExist = poBoxRegEx.test(extendedAddress.toLowerCase());
        if (!vm.cardSetup.addressValidationJSON.POBox.allowed && isPOBoxExist) {
            return { addressValidated: false, message: vm.cardSetup.addressValidationJSON.POBox.badSyntaxMessage[vm.languageCode] };
        }
        return { addressValidated: true };
    }

    vm.goToConfirmation = () => {
        let { addressValidated, message } = validateAddress(vm.redemptionAddress.address1, vm.redemptionAddress.address2, vm.redemptionAddress.provinceObj, vm.redemptionAddress.countryObj);
        vm.addressValidated = addressValidated;
        if (addressValidated) {
            vm.isAddressEmpty = isObjectEmpty(vm.redemptionAddress);
            vm.confirmDetails = true;
            vm.redemptionAddress.province = vm.redemptionAddress.provinceObj ? vm.redemptionAddress.provinceObj.code : '';
            vm.redemptionAddress.country = vm.redemptionAddress.countryObj ? vm.redemptionAddress.countryObj.code : '';
            vm.fullRedemptionAddress = `${vm.redemptionAddress.address1},<br/>${vm.redemptionAddress.city},<br/>${vm.redemptionAddress.province}, ${vm.redemptionAddress.countryObj.name}<br/>${vm.redemptionAddress.zip}`;
            if (vm.redemptionAddress.address2) {
                vm.fullRedemptionAddress = `${vm.redemptionAddress.address1},<br/>${vm.redemptionAddress.address2},<br/>${vm.redemptionAddress.city},<br/>${vm.redemptionAddress.province}, ${vm.redemptionAddress.countryObj.name}<br/>${vm.redemptionAddress.zip}`;
            }
            if (vm.redemptionAddress.companyName) {
                vm.fullRedemptionAddress = `${vm.redemptionAddress.companyName},<br/>${vm.fullRedemptionAddress}`;
            }
        } else {
            vm.addressError = message;
        }
    };

    vm.noMoreShipping = new Date(vm.cardSetup.shipOrderEndDate) < new Date();
    vm.noMoreReloads = new Date(vm.cardSetup.reloadOrderEndDate) < new Date();

    // Lasst shipped and reload dates conversations for the card
    vm.lastShippedDate = vm.cardSetup.lastShippedDate ? moment(vm.cardSetup.lastShippedDate).format('LL') : null;
    vm.lastReloadedDate = vm.cardSetup.lastReloadedDate ? moment(vm.cardSetup.lastReloadedDate).format('LL') : null;

    // TODO: get lost card function working just turn this bit to true
    vm.isLostCard = false;

    vm.userHasEnoughPoints = user.points > vm.cardSetup.redeemButtons[0].pointsCost;

    vm.neededPoints = vm.cardSetup.redeemButtons[0].pointsCost - user.points;

    vm.requestSinSkip = requestSinSkip;
    vm.showSinInfo = vm.cardSetup.taxInfo.isSinRequired && !vm.cardSetup.taxInfo.requestedSinSkip;
    vm.submitButtonOptions = {
        onSuccess() {
            console.log('submit success seen from reloadable Ctrl');
            $state.go($state.current, $stateParams, { reload: true });
        },
        onFail() {
            console.error('submit failed seen from reloadable Ctrl');
        }
    };

    vm.goToTab = (tab) => {
        $(`#cardcontent-${tab}`).tab('show');
        $(`#cardcontent-${tab}`).addClass('active');
        $(`#cardcontent-${tab}`).siblings().removeClass('active');
        $(`#cardtab-${tab}`).addClass('active');
        $(`#cardtab-${tab}`).siblings().removeClass('active');
        $state.go('.', { tab });
    };

    vm.selectAmount = (amount, pointsCost, isMaxValue) => {
        vm.selectedAmount = amount;
        vm.pointsCost = pointsCost;
        vm.currencyFilteredAmount = $filter('currency')(amount, undefined, 0);
        vm.isSelectedRedeemValue = true;
        vm.isMaxValueSelected = isMaxValue;
    };

    vm.closePopup = () => {
        vm.isSelectedRedeemValue = false;
        vm.redemptionError = false;
        vm.confirmDetails = false;
    };

    async function requestSinSkip() {
        try {
            await RewardService.requestSINSkip();
            $state.go($state.current, $stateParams, { reload: true });
            vm.showSinInfo = false;
        } catch (err) {
            console.error(err);
        }
    }

    vm.createRedemption = async function(amount) {
        try {
            const res = await ReloadableCardService.createRedemption({
                isNewCard: vm.cardSetup.isNewCard,
                reloadableSetupID: vm.cardSetup.reloadableSetupID,
                storeID: vm.cardSetup.storeID,
                amount,
                address: vm.redemptionAddress,
            });
            vm.successfulCardTransaction = true;

            // When card transaction is successful, refresh the user's points
            await Session.refreshPointBalance(res.points, true);
        } catch (err) {
            vm.successfulCardTransaction = false;
            vm.redemptionError = true;
            console.error(err);
            if (err.data) {
                vm.redemptionErrorMessage = err.data.message;
            }
        }
    };
    
    $timeout(() => {
        vm.goToTab($stateParams.tab || 'description');
    }, 0);

    vm.redeemLostCard = () => {
        // TODO: create a redemption with lost card address
        vm.lostRequestSubmitted = true;
    };

    vm.clearAddress = (isNewCard) => {
        vm.redemptionAddress = {};
        vm.isAddressEmpty = isObjectEmpty(vm.redemptionAddress);
        if (isNewCard) {
            document.getElementById('new-card-address').value = '';
        } else if (vm.isLostCard) {
            document.getElementById('lost-card-address').value = '';
        }
    }
}
